import {useRouter} from 'next/router'
import {useEffect} from "react"

const Home = () => {
  const router = useRouter()
  useEffect(() => {
    // Redirect to /store on mount
    router.push('/store')
  }, [router])
  return <div></div>
}

export default Home

// Open to users and guests
Home.acl = {
  action: "read",
  subject: "acl-page",
}
Home.authGuard = false
Home.guestGuard = false
